// Variables
@import "variables";
@import "default";
@import "ticket";

@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url('https://fonts.googleapis.com/css?family=Raleway');

/*
* Prefixed by:
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.login-section {
  background: url(../images/home/header-banner.jpg);
  background-size: auto;
  background-repeat: no-repeat;
}

.cm-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $default-primary;
  z-index: 111;

  .ps-sidebar-root {
    .css-dip3t8 {
      background-color: $default-primary;
    }
  }
}

main.layout-container {
  min-height: 100vh;
  background-color: #dbe3ef;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div {
    color: $default-primary;
    font-family: "Nunito", sans-serif;
  }

  .cm-sidebar {
    .ps-menu-root {
      .ps-menuitem-root {
        .css-kvqf1i {
          background-color: $default-secondary;
          border-radius: 8px;
          margin: 0 14px;
        }
        .ps-menu-button {
          &:hover {
            background-color: $default-secondary;
            border-radius: 8px;
            margin: 0 14px;
          }

          .ps-menu-label {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.form-label.required::after {
  content: " *";
  color: $default-secondary;
}

.layout-page {
  padding: 3.8rem 1.5rem;

  @media (min-width: 992px) {
    margin-left: 250px;
  }
}

.nav-link-avatar img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.dropdown-menu[data-bs-popper] {
  right: 0;
  left: unset;
}

.navbar {
  background-color: $white;
  position: fixed;
  left: 1.5rem;
  right: 1.5rem;
  top: 0;
  z-index: 11;

  @media (min-width: 992px) {
    left: calc(250px + 1.5rem);
  }
}

.aside-logo {
  width: 100px;
  height: auto;
  margin: 14px 0;
}

.content-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cm-sidebar span.ps-menu-label {
  color: $white;
}

.news-item {
  border-bottom: 5px solid #df01ae;

  .news-inner {
    .news-thumb {
      width: 100%;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    .news-content {
      border-bottom: 0;
    }
  }
}

.about-image {
  width: 100%;
  height: 500px;
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.ticket {
  background-color: $default-primary;
  border-radius: 6px;
  width: 100%;
  max-width: 100%;

  &__title {
    background-image: url(../images/home/ticket.svg);
    background-size: cover;
    background-position: bottom;
    -webkit-filter: drop-shadow(0 5px 0 rgba(255, 255, 255, 0.1));
    filter: drop-shadow(0 5px 0 rgba(255, 255, 255, 0.1));
    color: #ffffff !important;
    text-align: center;
    padding: 20px 0;
    font-size: 18px;
    font-weight: 900;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    &--price {
      border-top: 1px solid #ffffff;
    }
  }

  &__info {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &--type {
      color: #ffffff !important;

      &:last-child {
        border-left: 1px solid #ffffff;
      }

      &--text {
        color: #ffffff !important;

        &:first-child {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    &--width {
      width: 50%;
      color: #ffffff !important;
      padding: 20px 4px;
    }
  }
}

.pricing-item {
  .pricing-inner {
    .pricing-content {
      .get-ticket {
        ul {
          li.vat {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            h4 {
              color: #df01ae !important;
              margin-right: 5px;
            }

            p {
              color: #df01ae !important;
              font-family: "Arvo", serif;
            }
          }

          li.icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50%;
            color: #555555;
            -webkit-transition: 0.2s all linear;
            -o-transition: 0.2s all linear;
            transition: 0.2s all linear;
            z-index: 9;
            text-align: center;
            background-color: #fff;
          }

          li.ticket-text p {
            font-size: 16px;
            color: #fff;
            line-height: 24px;
            font-weight: 700;
            font-family: "Arvo", serif;
          }
        }
      }
    }
  }
}

.thankyou-card .rs-modal-content {
  background-color: #00102e;
}

.cm-title {
  text-shadow: 2px 2px 0 #df01ae;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  font-family: "Arvo", serif;
}

.profile-view {
  &__profile-img {
    border-radius: 50%;
    overflow: hidden;
    height: 12rem;
    width: 12rem;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.event-details--img-wrapper {
  width: 100%;
  height: 21.4rem;

  .event-img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.my-google-button-class {
  background-color: #ffffff;
  color: #818181;
  padding: 6px 12px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my-facebook-button-class {
  background-color: #1877f2;
  padding: 6px 12px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cm-lab-btn {
  margin-left: 24px;
}

.header-wrapper {
  padding: 6px 0;
}

@media (min-width: 1200px) {
  .banner-section .banner-wrapper .banner-content h1 {
    font-size: 60px;
  }
}

.carousel.carousel-slider .control-arrow {
  z-index: 111 !important;
}

.css-1wvake5.ps-broken {
  position: fixed !important;
  height: 100%;
  top: 0px;
  z-index: 100;
  left: -250px;
}

.ps-sidebar-root.css-1wvake5 {
  position: fixed;
  height: 100%;
  top: 0px;
  z-index: 100;
  left: -250px;
}

@media (min-width: 993px) {
  .ps-sidebar-root.css-1wvake5 {
    left: 0;
  }
}

.ps-menu-button.ps-active {
  background-color: #df01ae;
  border-radius: 8px;
  margin: 0 14px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.required:after {
  content: " *";
  color: red;
}

.new-ticket {
  margin: auto;
  display: inline-flex;
  background: white;
  overflow: hidden;
  border-radius: 18px;
  position: relative;
  border: 1px solid #00163f;
  // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.new-ticket .left {
  display: flex;
}

.new-ticket .image {
  height: auto;
  width: 300px;
  // background-image: url("https://s.yimg.com/ny/api/res/1.2/Z7_veDUdwMryJWHtTVCkMg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTcxOTtjZj13ZWJw/https://media.zenfs.com/en/the_independent_577/59c5db5af93e84038e82db81dfc7268b");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.95;
}

.new-ticket .admit-one {
  position: absolute;
  color: darkgray;
  white-space: nowrap;
  // height: 250px;
  padding: 0 10px;
  letter-spacing: 0.15em;
  font-size: 12px;
  display: flex;
  text-align: center;
  justify-content: space-around;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}

.new-ticket .left .admit-one span {
  color: white;
  font-weight: 700;
}

.new-ticket .left .ticket-number {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  color: white;
}

.new-ticket .ticket-info {
  padding: 10px 20px;
  display: flex;
  width: 500px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.new-ticket .date {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  color: #505050;
  padding: 5px 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.new-ticket .date span:first-child {
  text-align: left;
  width: 100px;
}

.new-ticket .date span:last-child {
  text-align: right;
  width: 100px;
}

.new-ticket .date .nov-10 {
  color: #d62839;
  font-size: 18px;
  width: 150px;
}

.new-ticket .show-name {
  font-size: 18px;
  color: #d62839;
  font-weight: 700;
}

.new-ticket .show-name h1 {
  font-size: 22px;
  letter-spacing: 0.05em;
  margin-bottom: 6px;
  color: #93229f;
  font-family: "Saira Stencil One", sans-serif;
}

.new-ticket .show-name h2 {
  margin-bottom: 12px;
  color: #505050;
  font-size: 32px;
}

.new-ticket .time {
  color: #505050;
  text-align: center;
  display: flex;
  flex-direction: column;
  // gap: 10px;
  font-weight: 700;
}

.new-ticket .time span {
  font-weight: 400;
  color: darkgray;
}

.new-ticket .left .time {
  font-size: 16px;
  margin-bottom: 5px;
}

.new-ticket .tagline {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  color: #505050;
}

.new-ticket .location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  border-top: 1px solid gray;
  font-weight: 700;
  color: #505050;
}

.new-ticket .location span:first-child {
  text-align: left;
}

.new-ticket .location span:last-child {
  text-align: right;
}

.new-ticket .location .separator {
  font-size: 20px;
}

.new-ticket .right {
  width: 250px;
  border-left: 1px dashed #404040;
}

.new-ticket .right .admit-one {
  color: darkgray;
}

.new-ticket .right .admit-one span:nth-child(2) {
  color: #505050;
}

.new-ticket .right .right-info-container {
  height: 250px;
  padding: 10px 10px 10px 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.new-ticket .right .show-name h1 {
  font-size: 18px;
  font-family: "Barlow", sans-serif;
}

.new-ticket .right .time {
  margin-top: 10px;
}

.new-ticket .barcode {
  height: 100px;
}

.new-ticket .barcode img {
  height: 100%;
}

.new-ticket .right .ticket-number {
  color: #505050;
  font-weight: 700;
}

.new-ticket .totalticketcount {
  color: #ffffff;
  text-align: center;
}

@media print {
  .d-print-none {
    display: none;
  }

  #showTickets {
    zoom: 55%;
  }

  .totalticketcount {
    color: #000000;
    font-size: 16px;
  }
}

.table-responsive {
  max-height: 510px;
}

.table-responsive thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.ticker-img-wrapper {
  position: relative;
  height: 250px;
  width: 250px;

  .ticker-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}



.box {
  display: block;
  min-width: 300px;
  height: 275px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.upload-options {
  position: relative;
  height: 50px;
  background-color: cadetblue;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  transition: background-color ease-in-out 150ms;
}
.upload-options:hover {
  background-color: #7fb1b3;
}
.upload-options input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-options label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}
.upload-options label::after {
  content: 'add';
  font-family: 'Material Icons';
  position: absolute;
  font-size: 2.5rem;
  color: rgba(230, 230, 230, 1);
  top: calc(70% - 2.5rem);
  left: calc(50% - 1.25rem);
  z-index: 0;
}
.upload-options label span {
  display: inline-block;
  width: 50%;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}
.upload-options label span:hover i.material-icons {
  color: lightgray;
}
.js--image-preview {
  height: 225px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('');
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.js--image-preview::after {
  content: "photo_size_select_actual";
  font-family: 'Material Icons';
  position: relative;
  font-size: 4.5em;
  color: rgba(230, 230, 230, 1);
  top: calc(50% - 3rem);
  left: calc(50% - 2.25rem);
  z-index: 0;
}
.js--image-preview.js--no-default::after {
  display: none;
}
.js--image-preview:nth-child(2) {
  background-image: url('http://bastianandre.at/giphy.gif');
}
i.material-icons {
  transition: color 100ms ease-in-out;
  font-size: 2.25em;
  line-height: 55px;
  color: white;
  display: block;
}
.drop {
  display: block;
  position: absolute;
  background: rgba(95, 158, 160, 0.2);
  border-radius: 100%;
  transform: scale(0);
}
.animate {
  animation: ripple 0.4s linear;
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
 }
}