/*
* Prefixed by:
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

html {
  scroll-behavior: smooth;
}

.c-pointer {
  cursor: pointer;
}

p {
  color: inherit;
}

.cm-bg {
  background-color: $default-primary;
}

.text-default {
  color: $default-primary;
}

.text-default-secondary {
  color: $default-secondary;
}

.rs-panel {
  background-color: $white;
}

.rs-panel-title {
  font-weight: 700;
  text-transform: capitalize;
}

.ck.ck-content:not(.ck-comment__input *) {
  height: 250px;
  overflow-y: auto;
}

.h-underline:hover {
  text-decoration: underline;
}

.rs-calendar-month-dropdown-cell,
.rs-calendar-month-dropdown-year,
.rs-calendar-table-cell .rs-calendar-table-cell-content {
  color: $default-primary;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
}

.zoom-in {
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;

  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  }
}

footer {
  .footer-top {
    .footer-top-item {
      padding: 20px;
    }
  }
}

.cm-loader {
  .rs-loader-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1111;
    overflow: hidden;

    .rs-loader-content {
      color: #8e8e93;
    }
  }
}

.cm-table {
  width: 100%;

  thead {
    tr {
      th {
        border: 1px solid $default-primary;
        padding: 8px;
        font-size: 14px;
        text-align: center;
        text-wrap: nowrap;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid $default-primary;
        padding: 8px;
        font-size: 14px;
        font-weight: 500;
        text-align: start;
      }
    }
  }
}

.cm-table-report {
  thead {
    tr {
      th {
        border: 1px solid #ffffff;
        background-color: $default-primary;
        color: #ffffff;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid $default-primary;
        background-color: #ffffff;
      }
    }
  }
}

.css-1cuxlhl,
.css-jn69v9 {
  color: #ffffff;
}

.css-wx7wi4 {
  width: unset !important;
  min-width: unset !important;
}

.ps-submenu-content ul {
  background-color: #001b4e;
}

.css-k07e09 {
  background-color: #df01ae !important;
  border-radius: 8px;
  margin: 0 14px;
}

.rs-loader-backdrop-wrapper {
  z-index: 999;
}