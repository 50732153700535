$grey-light: hsl(200, 10%, 92%);
$grey: hsl(200, 10%, 85%);
$grey-dark: hsl(200, 10%, 70%);
$shadow: hsla(200, 20%, 20%, 0.25);

$bg: hsl(200, 0%, 100%);
$divider: $grey-light;
$border: $grey-dark;

$cutout-size: 1rem;

/*
* Prefixed by:
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.ticket {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  max-width: 24rem;
  &__header,
  &__body,
  &__footer {
    padding: 1.25rem;
    background-color: $bg;
    border: 1px solid $border;
    -webkit-box-shadow: 0 2px 4px $shadow;
    box-shadow: 0 2px 4px $shadow;
  }
  &__header {
    font-size: 1.5rem;
    border-top: 0.25rem solid $default-secondary;
    border-bottom: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &__wrapper {
    -webkit-box-shadow: 0 2px 4px $shadow;
    box-shadow: 0 2px 4px $shadow;
    border-radius: 0.375em 0.375em 0 0;
    overflow: hidden;
  }
  &__divider {
    position: relative;
    height: $cutout-size;
    background-color: $bg;
    margin-left: ($cutout-size / 2);
    margin-right: ($cutout-size / 2);
    &::after {
      content: "";
      position: absolute;
      height: 50%;
      width: 100%;
      top: 0;
      border-bottom: 2px dashed $divider;
    }
  }
  &__notch {
    position: absolute;
    left: ($cutout-size / 2) * -1;
    width: $cutout-size;
    height: $cutout-size;
    overflow: hidden;
    &::after {
      content: "";
      position: relative;
      display: block;
      width: ($cutout-size * 2);
      height: ($cutout-size * 2);
      right: 100%;
      top: -50%;
      border: ($cutout-size / 2) solid $bg;
      border-radius: 50%;
      -webkit-box-shadow: inset 0 2px 4px $shadow;
      box-shadow: inset 0 2px 4px $shadow;
    }
    &--right {
      left: auto;
      right: ($cutout-size / 2) * -1;
      &::after {
        right: 0;
      }
    }
  }
  &__body {
    border-bottom: none;
    border-top: none;
    & > * + * {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid $divider;
    }
  }
  &__section {
    & > * + * {
      margin-top: 0.25rem;
    }
    & > h3 {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }
  }
  &__header,
  &__footer {
    font-weight: bold;
    font-size: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  &__footer {
    border-top: 2px dashed $divider;
    border-radius: 0 0 0.325rem 0.325rem;
  }
}
